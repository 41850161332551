
<template>
  <div class="about" >
    <div class="questionTitle"></div>
    <div class="questionTime">
      <van-image
              width="20px"
              height="20px"
              :src="'https://sxd.kk.aliyun.com/img/dingshinaozhong.png'"
      />
      <div class="questionTex">题库刷新</div>
      <div class="questionTimer">{{questionTimer}}</div>
    </div>

    <div class="aboutBody">
      <swiper class="swiper-container" :pagination="{ clickable: true}" direction="vertical" >
        <swiper-slide class="swiper-slide" >
          <div class="slideBox ">
            <div class="itemNum" >01</div>
            <div class="itemQuestion">{{questOne.question}}</div>
            <div class="itemChooseBox">
              <div class="itemChoose" :class="one=='A'?'activeItem':''" @click="chooseOne('A')">A {{questOne.a}}</div>
              <div class="itemChoose" :class="one=='B'?'activeItem':''" @click="chooseOne('B')">B {{questOne.b}}</div>
              <div class="itemChoose" :class="one=='C'?'activeItem':''" @click="chooseOne('C')">C {{questOne.c}}</div>
              <div class="itemChoose" :class="one=='D'?'activeItem':''" @click="chooseOne('D')">D {{questOne.d}}</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide" >
          <div class="slideBox ">
            <div class="itemNum"  >02</div>
            <div class="itemQuestion">{{questTwo.question}}</div>
            <div class="itemChooseBox">
              <div class="itemChoose" :class="two=='A'?'activeItem':''" @click="chooseTwo('A')">A {{questTwo.a}}</div>
              <div class="itemChoose" :class="two=='B'?'activeItem':''" @click="chooseTwo('B')">B {{questTwo.b}}</div>
              <div class="itemChoose" :class="two=='C'?'activeItem':''" @click="chooseTwo('C')">C {{questTwo.c}}</div>
              <div class="itemChoose" :class="two=='D'?'activeItem':''" @click="chooseTwo('D')">D {{questTwo.d}}</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide" >
          <div class="slideBox ">

            <div class="itemNum" >03</div>
            <div class="itemQuestion">{{questThr.question}}</div>
            <div class="itemChooseBox">
              <div class="itemChoose" :class="thr=='A'?'activeItem':''" @click="chooseThr('A')">A {{questThr.a}}</div>
              <div class="itemChoose" :class="thr=='B'?'activeItem':''" @click="chooseThr('B')">B {{questThr.b}}</div>
              <div class="itemChoose" :class="thr=='C'?'activeItem':''" @click="chooseThr('C')">C {{questThr.c}}</div>
              <div class="itemChoose" :class="thr=='D'?'activeItem':''" @click="chooseThr('D')">D {{questThr.d}}</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide" >
          <div class="slideBox ">
            <div class="itemNum" >04</div>
            <div class="itemQuestion">{{questFour.question}}</div>
            <div class="itemChooseBox">
              <div class="itemChoose" :class="four=='A'?'activeItem':''" @click="chooseFour('A')">A {{questFour.a}}</div>
              <div class="itemChoose" :class="four=='B'?'activeItem':''" @click="chooseFour('B')">B {{questFour.b}}</div>
              <div class="itemChoose" :class="four=='C'?'activeItem':''" @click="chooseFour('C')">C {{questFour.c}}</div>
              <div class="itemChoose" :class="four=='D'?'activeItem':''" @click="chooseFour('D')">D {{questFour.d}}</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide" >
          <div class="slideBox ">
            <div class="itemNum" >05</div>
            <div class="itemQuestion">{{questFiv.question}}</div>
            <div class="itemChooseBox">
              <div class="itemChoose" :class="fiv=='A'?'activeItem':''" @click="chooseFiv('A')">A {{questFiv.a}}</div>
              <div class="itemChoose" :class="fiv=='B'?'activeItem':''" @click="chooseFiv('B')">B {{questFiv.b}}</div>
              <div class="itemChoose" :class="fiv=='C'?'activeItem':''" @click="chooseFiv('C')">C {{questFiv.c}}</div>
              <div class="itemChoose" :class="fiv=='D'?'activeItem':''" @click="chooseFiv('D')">D {{questFiv.d}}</div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination"></div>
      </swiper>
      <div class="questionList">

      </div>
      <div class="subBtn" @click="toAnswer">提交答案</div>
    </div>
  </div>
</template>

<script>
  import {onMounted, reactive, toRefs} from "vue";
  import { useRouter } from 'vue-router'

  import { Swiper, SwiperSlide } from "swiper/vue";
  import SwiperCore, {
    Pagination,A11y
  } from "swiper";
  import "swiper/swiper-bundle.css";
  SwiperCore.use([ Pagination,A11y]);

  import { answer ,getQuestion} from '@/service/user'
  import {Toast} from "vant";
export default {

  components: {
    Swiper,
    SwiperSlide,
  },
  setup(){
    const router = useRouter();
    const state = reactive({
      isPortrait:true,//竖屏
      questionTimer:"",
      answerTimes:0,
      one:"",
      fiv:"",
      thr:"",
      two:"",
      four:"",
      questOne:{
        question:'',
        a:"",
        b:"",
        c:"",
        d:"",
        answer:""
      },
      questTwo:{
        question:'',
        a:"",
        b:"",
        c:"",
        d:"",
        answer:""
      },
      questThr:{
        question:'',
        a:"",
        b:"",
        c:"",
        d:"",
        answer:""
      },
      questFour:{
        question:'',
        a:"",
        b:"",
        c:"",
        d:"",
        answer:""
      },
      questFiv:{
        question:'',
        a:"",
        b:"",
        c:"",
        d:"",
        answer:""
      }
    })
    const chooseOne=(value)=>{
      //console.log(value);
      state.one=value;
    }
    const chooseFiv=(value)=>{
      state.fiv=value;
    }
    const chooseFour=(value)=>{
      state.four=value;
    }
    const chooseThr=(value)=>{
      state.thr=value;
    }
    const chooseTwo=(value)=>{
      state.two=value;
    }
    // 获取小时、分、秒
    function add0(m){return m<10?'0'+m:m }
    function format(shijianchuo)
    {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(parseInt(shijianchuo));
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return add0(h)+':'+add0(mm)+':'+add0(s);
    }
    const getQuest=()=>{
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration:0
      });
      getQuestion({}).then(res=>{
        //console.log(res);
        if(res.code==0){
          //console.log(JSON.parse(res.data));
          state.questOne=JSON.parse(res.data).sxdQuestionAndAnswersMap['1'];
          state.questTwo=JSON.parse(res.data).sxdQuestionAndAnswersMap['2'];
          state.questThr=JSON.parse(res.data).sxdQuestionAndAnswersMap['3'];
          state.questFour=JSON.parse(res.data).sxdQuestionAndAnswersMap['4'];
          state.questFiv=JSON.parse(res.data).sxdQuestionAndAnswersMap['5'];
          state.questionTimer=format(JSON.parse(res.data).sxdQuestionCountDown);
          Toast.clear();
        }

      }).catch(()=>{
        Toast.clear();
      })
    }
    const toAnswer=()=>{
      if(!state.one){
        Toast("请下滑屏幕选择第一题答案");
        return;
      }
      if(!state.two){
        Toast("请下滑屏幕选择第二题答案");
        return;
      }
      if(!state.thr){
        Toast("请下滑屏幕选择第三题答案");
        return;
      }
      if(!state.four){
        Toast("请下滑屏幕选择第四题答案");
        return;
      }
      if(!state.fiv){
        Toast("请下滑屏幕选择第五题答案");
        return;
      }
      answer({
        "answer": state.one+state.two+state.thr+state.four+state.fiv,
      }).then(res=>{
        //console.log(res);
        if(res.code==1){
          Toast(res.msg)
        }else {
          if(res.data.state){
            localStorage.setItem('answerTimes',0);
            router.push({ path: "/home" })
          }else {
            if(localStorage.getItem('answerTimes')>2){
              Toast('打错次数太多了，请重新登录')
              localStorage.setItem('answerTimes',0);
              router.push({ path: "/login" });
            }
            state.answerTimes++;
            localStorage.setItem('answerTimes',state.answerTimes)
            Toast(res.data.message)
          }
          // setLocal('token', res.data.token);
          // setLocal('tokenExpireTime', res.data.tokenExpireTime);
          // if(res.data.isAnswerQuestion){
          //   router.push({ path: "/about" })
          // }else {
          //   router.push({ path: "/home" })
          // }
          // window.location.href = '/home'
        }

      })
    }

    //长宽判断然后显示图片
    const system = ()=>{
      const width= document.body.clientWidth;
      const height=document.body.clientHeight;
      if(width>height){
        state.isPortrait=false;
      }else {
        state.isPortrait=true;
      }
    }
    onMounted(()=>{
      system();
      getQuest();
    })
    return{
      ...toRefs(state),
      chooseOne,
      chooseThr,
      chooseFour,
      chooseTwo,
      chooseFiv,
      toAnswer,
      getQuest,
      system,
      modules: [ Pagination, A11y],
    }
  },

}
</script>

<style lang="less" scoped>
  .about{
    width: 100%;
    height: 100%;
    overflow: scroll;
    background: url("https://sxd.kk.aliyun.com/img/question.png") no-repeat center;
    background-size: 100% 100%;
    .questionTitle{
      margin-top: 38px;
      width: 100%;
      height: 90px;
      background: url("https://sxd.kk.aliyun.com/img/questionTitle.png") no-repeat center;
      background-size: 284px 90px;
    }
    .questionTime{
      display: flex;
      justify-content: center;
      align-items: center;
      .questionTex{
        margin-left: 10px;
        margin-right: 15px;
        font-size: 18px;
        font-weight: bold;

      }
      .questionTimer{
        font-size: 32px;
        font-weight: bold;
        color: #EA4F47;
      }
    }
    .questionTimes{
      padding: 0 25px;
      margin-top: 10px;

    }
    .aboutBody{
      padding: 15px 20px;
      .swiper-container {
        /*width: 7.1rem;*/
        height: 350px;
        padding-left: 10px;
        border-radius: 0.1rem;
        --swiper-theme-color: #ff6600;
        --swiper-pagination-color: rgba(234, 79, 71, 0.8);
        --swiper-navigation-color: rgba(234, 79, 71, 0.8);
        --swiper-navigation-size: 20px;
        /deep/.swiper-pagination{
          left: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          width: 10px;

        }
        /deep/.swiper-pagination-bullet{
          width: 5px;
          height: 15%;
          border-radius: 0;
        }
      }
      /*.swiper-slide{*/
      /*  margin:  5px;*/

      /*}*/
      .swiper-slide .slideBox{
        width: 100%;
        height: 100%;
        .itemNum{
          /*margin-top: 30px;*/
          width: 45px;
          height: 26px;
          color: #ffffff;
          font-size: 18px;
          line-height: 26px;
          background-color: rgba(234, 79, 71, 1);
          text-align: center;
        }
        .itemQuestion{
          padding: 9px 13px;
          border: 0.06rem solid rgba(234, 79, 71, 1);
          font-size: 18px;
          font-weight: bold;
        }
        .itemChooseBox{
          /*display: flex;*/
          .itemChoose{
            /*width: 100%;*/
            margin-top: 9px;
            padding: 7px;
            font-size: 18px;
            font-weight: bold;
            background: rgba(216, 216, 216, 0);
            border: 0.06rem solid #EA4F47;
            /*flex-wrap: wrap;*/
          }
          .activeItem{
            background: url("https://sxd.kk.aliyun.com/img/zhengque.png") no-repeat ;
            background-color: rgba(234, 79, 71, 0.2);
            background-position: 95% ,center;
            background-size: 18px 18px;
          }
        }
      }
    }
    .subBtn{
      margin-top: 10px;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      text-align: center;
      border: 0.1rem solid rgba(234, 79, 71, 1);
      background-color: rgba(234, 79, 71, 0.6);
    }


  }
  @media screen and (orientation: landscape) {
    /*横屏样式*/
    .about {
      width: 100%;
      height: 100%;
      overflow: scroll;
      background: url("https://sxd.kk.aliyun.com/img/questionTitle1.png") no-repeat center;
      background-size: 100% 100%;
      .questionTitle {
        margin-top: 10px;
        width: 100%;
        height: 15%;
        background: url("https://sxd.kk.aliyun.com/img/question1.png") no-repeat center;
        background-size: 50% 100%;
      }
      .questionTime{
        .questionTex{
          font-size: 14px;

        }
        .questionTimer{
          font-size: 18px;
        }
      }
      .aboutBody{
        padding: 0 15%;
        height: 45%;
        margin-top: 2%;
        .swiper-container {
          height: 100%;
        }
        .swiper-slide .slideBox{
          .itemQuestion{
            padding: 9px 13px;
            border: 0.06rem solid rgba(234, 79, 71, 1);
            /*font-size: 1.16px;*/
          }
          .itemChooseBox{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .itemChoose{
              /*float: left;*/
              margin-top: 5px;
              flex-shrink: 0;
              width: 40%;
              padding: 9px 13px;
              text-indent: 2px;
              border: 0.06rem solid rgba(234, 79, 71, 1);
              /*font-size: 1.16px;*/
            }
          }
        }
      }
      .subBtn{
        /*font-size: 18px;*/
        text-align: center;
        border: 0.06rem solid rgba(234, 79, 71, 1);
        background-color: rgba(234, 79, 71, 0.6);
      }
    }
  }
</style>
