<template>
    <div class="setBox">
        <div v-for="(item,index) in list"  :key="index">
            <van-cell-group inset>
                <van-field
                        v-model="item.question"
                        rows="1"
                        autosize
                        :label="'问题'+(index+1)"
                        type="textarea"
                        placeholder="请输入问题1"
                />
                <van-field
                        v-model="item.a"
                        label="答案A"
                        placeholder="请输入答案A"
                />
                <van-field
                        v-model="item.b"
                        label="答案B"
                        placeholder="请输入答案B"
                />
                <van-field
                        v-model="item.c"
                        label="答案C"
                        placeholder="请输入答案C"
                />
                <van-field
                        v-model="item.d"
                        label="答案D"
                        placeholder="请输入答案D"
                />
                <van-radio-group class="sureQuestion" v-model="item.answer" direction="horizontal">
                    <span style="line-height: 1.25rem;font-size: 0.4rem">正确答案：</span>
                    <van-radio name="A">A</van-radio>
                    <van-radio name="B">B</van-radio>
                    <van-radio name="C">C</van-radio>
                    <van-radio name="D">D</van-radio>
                </van-radio-group>
            </van-cell-group>

        </div>
        <van-field
                v-model="chooseCountDown"
                label="题库刷新时间"
                placeholder="请选择时间"
                readonly
                @click="getTime"
        />
        <van-popup :show="dateShow" position="bottom">
            <van-datetime-picker
                    v-model="countDown"
                    type="datetime"
                    title="选择时间"
                    @confirm="handleDateConfirm"
                    @cancel="handleDateCancle"
            />
        </van-popup>

        <van-button type="primary" size="large" @click="submit">提交</van-button>
    </div>
</template>

<script>
    import {reactive, toRefs} from "vue";
    import {Toast} from "vant";
    import {setQuestion} from '@/service/user'
    export default {
        name: "SetQuestIon",
        setup(){
            const state = reactive(
                {
                    list: [
                        {
                            "a": "",
                            "answer": "",
                            "b": "",
                            "c": "",
                            "code": "1",
                            "d": "",
                            "question": ""
                        },
                        {
                            "a": "",
                            "answer": "",
                            "b": "",
                            "c": "",
                            "code": "2",
                            "d": "",
                            "question": ""
                        },
                        {
                            "a": "",
                            "answer": "",
                            "b": "",
                            "c": "",
                            "code": "3",
                            "d": "",
                            "question": ""
                        },
                        {
                            "a": "",
                            "answer": "",
                            "b": "",
                            "c": "",
                            "code": "4",
                            "d": "",
                            "question": ""
                        },
                        {
                            "a": "",
                            "answer": "",
                            "b": "",
                            "c": "",
                            "code": "5",
                            "d": "",
                            "question": ""
                        }
                    ],
                    countDown:new Date(),
                    chooseCountDown:"",
                    dateShow:false
                }
            )
            const handleDateConfirm=(value)=> {
                // console.log(value);
                state.chooseCountDown=toStringTime(value);
                state.dateShow=false;

            }
            function add0(m){return m<10?'0'+m:m }
            function toStringTime(time) {
                var y = time.getFullYear();
                var m = time.getMonth()+1;
                var d = time.getDate();
                var h = time.getHours();
                var mm = time.getMinutes();
                var s = time.getSeconds();
                return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s);

            }
            const handleDateCancle=()=> {
                state.dateShow=false;

            }
            const getTime=()=>{
                // console.log(555);
                state.dateShow=true;
                // console.log(state.dateShow);
            }
            const submit=()=>{
                for (let i=0;i<state.list.length;i++){
                    if(state.list[i].question==""){
                        Toast("请输入第"+(i+1)+"题问题");
                        return;
                    }
                    if(state.list[i].a==""){
                        Toast("请输入第"+(i+1)+"题A答案");
                        return;
                    }
                    if(state.list[i].b==""){
                        Toast("请输入第"+(i+1)+"题B答案");
                        return;
                    }
                    if(state.list[i].c==""){
                        Toast("请输入第"+(i+1)+"题C答案");
                        return;
                    }
                    if(state.list[i].d==""){
                        Toast("请输入第"+(i+1)+"题D答案");
                        return;
                    }
                    if(state.list[i].answer==""){
                        Toast("请选择第"+(i+1)+"题正确答案");
                        return;
                    }
                }
                if(state.chooseCountDown==''){
                    Toast("请输选择题库刷新时间");
                    return;
                }

                Toast.loading({
                    message: '正在提交...',
                    forbidClick: true,
                    duration:0
                });
                setQuestion({sxdQuestionAndAnswersJson:JSON.stringify(state.list),sxdQuestionCountDown:state.chooseCountDown}).then((res)=>{
                    // console.log(res);
                    Toast.clear();
                    Toast("恭喜您，提交成功！");
                    // if(res.code==0){
                    //     Toast("恭喜您，提交成功！");
                    // }else {
                    //     Toast("提交失败！");
                    // }
                }).catch(()=>{
                    Toast.clear();
                    Toast("提交失败！");
                })
            }
            return{
               ...toRefs(state),
                handleDateConfirm,
                handleDateCancle,
                getTime,
                submit
            }
        }
    }
</script>

<style scoped lang="less">
.setBox{
    background-color: #ffffff;
    /deep/.sureQuestion{
        padding-left: 20px;
    }
}
</style>